.caption
  font-size: small
  text-align: center
  font-weight: bold

.update
  font-size: small
  color: #919191

ul.post-list
  li
    line-height: 1.5

.icon
  width: 16px
  color: #ededed

.floated
  float: right
  padding-left: 20px

.site-footer
  background-color: #324047
  svg
    display: inline-block
    width: 16px
    height: 16px
    vertical-align: middle
    path
      fill: #333333

@import 'syntax-highlighting'
